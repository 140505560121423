






























import { getTxHref } from '@/chains'
import { InvestorPlatformClient, TransactionStatus, TransactionType, TxHistory } from '@/client'
import { List } from 'linq-collections'
import { Vue, Component, Watch } from 'vue-property-decorator'

enum OrderType {
  Solo = 0,
  Shared = 1,
  Other = 2
}

@Component({})
export default class Transactions extends Vue {
  tableHeaders = [
    { text: 'Time', value: 'ts' },
    { text: 'Type', value: 'transaction_type' },
    { text: 'Tx', value: 'transaction_hash' },
    { text: 'Status', value: 'status' },
    { text: 'Order Type', value: 'order_type' },
    { text: 'Error', value: 'error_reason'}
  ]
  txHistories: TxHistory[] = []
  totalItems = 0
  maxId = -1
  options = {
    page: 1
  }
  isLoading = false

  get displayTxHistories() {
    return this.txHistories.map(h => ({
      ...h,
      transaction_type: TransactionType[h.transaction_type],
      status: TransactionStatus[h.status],
      order_type: OrderType[h.order_type!] ?? 'n/a'
    }))
  }

  async mounted() {
    await this.fetchPage(0)
  }

  async fetchPage(page: number) {
    this.isLoading = true
    try {
      const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
      const { value, totalItems } = await client.getTxHistories(page, this.maxId)
      this.txHistories = value
      this.totalItems = this.totalItems != 0 ? this.totalItems : totalItems
      this.maxId = this.maxId != -1 ? this.maxId : new List(value).max(h => h.id)
    } finally {
      this.isLoading = false
    }
  }

  getScannerLink(txHistory: TxHistory) {
    try {
      return getTxHref(txHistory.transaction_hash!, txHistory.chain_id)
    } catch (e) {
      console.error(e)
      return null
    }
  }
  
  getClippedTx(tx: string) {
    return tx.substring(0, 12) + '...'
  }

  @Watch('options.page')
  async watchPage(val: number) {
    this.txHistories = []
    await this.fetchPage(val - 1)
  }
}
